import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import PageTitle from "@components/admin/PageTitle";
import AddShippingRateButtonComponent from "@components/admin/shipping-rates/AddShippingRateButtonComponent";
import EditShippingRateButtonComponent from "@components/admin/shipping-rates/EditShippingRateButtonComponent";
import { Box, Button, Card, CardContent, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { AdminGetEnumsFromShippingProviderTypeResponse, AdminProviderShippingRatesByEnumsGetResponse } from "@reshopper/admin-client";
import { ShippingProviderType } from "@reshopper/web-client";
import { performAction } from "@utils/actions";
import { toDanishPriceFormat } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useEffect, useState } from "react";


export default withAdminMenuLayout(function RecentTransactionsPageComponent() {
	const [shippingProviderTypeEnums, setEnumValues] = useState<AdminGetEnumsFromShippingProviderTypeResponse>();
	const [ProviderShippingRates, setShippingProviderShippingRates] = useState<AdminProviderShippingRatesByEnumsGetResponse[]>();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [currentProviderShippingRates, setCurrentProviderShippingRates] = useState<AdminProviderShippingRatesByEnumsGetResponse | null>(null);
	const [shippingRateIndexToDelete, setShippingRateIndexToDelete] = useState<number | null>(null);


	useEffect(() => {
		const fetchEnumData = async () => {
			const enumDataResponse = await adminClient().adminGetEnumsFromShippingProviderType();
			setEnumValues(enumDataResponse);
			if (enumDataResponse.shippingProviderTypes !== undefined) {
				var shippingProviderRates = await fetchProviderShippingRatesData(enumDataResponse.shippingProviderTypes)
				setShippingProviderShippingRates(shippingProviderRates);
			}
		}
		const fetchProviderShippingRatesData = async (shippingProviderTypesEnums: ShippingProviderType[]) => {
			return await adminClient().adminProviderShippingRatesByEnumsGet({ shippingProviderTypes: shippingProviderTypesEnums });
		}
		fetchEnumData();
	}, []);

	function createShippingProviderTable(ProviderShippingRates: AdminProviderShippingRatesByEnumsGetResponse) {
		return <>
			{ProviderShippingRates &&
				<Card style={{ marginTop: 32, marginBottom: 32 }}>
					<CardContent>
						<Typography style={{ marginBottom: 32 }} variant="h4">{ProviderShippingRates.shippingProviderType}</Typography>
						<AddShippingRateButtonComponent
							providerShippingRates={ProviderShippingRates}
						/>
						<Box
							style={{
								marginBottom: 32
							}}
						>
						</Box>
						<Paper>
							<Table size="small">
								<TableBody>
									{ProviderShippingRates?.shippingRates.map((rate, index) =>
										<>
											<EditShippingRateButtonComponent
												providerShippingRates={ProviderShippingRates}
												shippingRate={rate}
												index={index}
											/>
											<Button
												startIcon={<DeleteIcon />}
												onClick={() => {
													setCurrentProviderShippingRates(ProviderShippingRates)
													setShippingRateIndexToDelete(index)
													setOpenDeleteDialog(true)
												}}
											>
												Delete
											</Button>

											<TableRow>
												<TableCell><b>Internal Price</b></TableCell>
												<TableCell><b>Uptil Weight</b></TableCell>
											</TableRow>

											<TableRow>
												<TableCell>
													{toDanishPriceFormat(rate.amountInHundredsInternal / 100)}
												</TableCell>
												<TableCell>
													{rate.uptilWeight}
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell><b>Normal Price</b></TableCell>
												<TableCell><b>Discounted Normal Price</b></TableCell>
												<TableCell><b>Normal Discount valid from date</b></TableCell>
												<TableCell><b>Normal Discount valid to date</b></TableCell>
											</TableRow>

											<TableRow>
												<TableCell>
													{toDanishPriceFormat(rate.amountInHundredsFreeUser / 100)}
												</TableCell>
												<TableCell>
													{(rate.discountFree?.discountedPriceInHundreds != undefined ? toDanishPriceFormat(rate.discountFree?.discountedPriceInHundreds / 100) : "")}
												</TableCell>
												<TableCell>
													{toDanishDateFormat(rate.discountFree?.validFromUtc)}
												</TableCell>
												<TableCell>
													{toDanishDateFormat(rate.discountFree?.validToUtc)}
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell><b>Plus Price</b></TableCell>
												<TableCell><b>Discounted Plus Price</b></TableCell>
												<TableCell><b>Plus Discount valid from date</b></TableCell>
												<TableCell><b>Plus Discount valid to date</b></TableCell>
											</TableRow>

											<TableRow>
												<TableCell>
													{toDanishPriceFormat(rate.amountInHundredsPlus / 100)}
												</TableCell>
												<TableCell>
													{(rate.discountPlus?.discountedPriceInHundreds != undefined ? toDanishPriceFormat(rate.discountPlus?.discountedPriceInHundreds / 100) : "")}
												</TableCell>
												<TableCell>
													{toDanishDateFormat(rate.discountPlus?.validFromUtc)}
												</TableCell>
												<TableCell>
													{toDanishDateFormat(rate.discountPlus?.validToUtc)}
												</TableCell>
											</TableRow>

											<Box
												style={{
													marginBottom: 16
												}}
											>
											</Box>
										</>
									)
									}
								</TableBody>
							</Table>
						</Paper>
					</CardContent>
				</Card>
			}
			<ConfirmationDialogComponent
				isDialogOpen={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				onConfirm={onDelete}
				title="Confirm Deletion"
				description={`Are you sure you want to delete the shipping rate ?`}
			/>
		</>;
	}

	function onDelete() {
		if (!shippingRateIndexToDelete)
			throw new Error("No package specified.");

		if (!currentProviderShippingRates)
			throw new Error("No package specified.");

		currentProviderShippingRates.shippingRates.splice(shippingRateIndexToDelete, 1);

		performAction(
			async () => adminClient().adminUpdateShippingRatesForProviderShippingRatesPut(
				{
					body: {
						shippingRates: currentProviderShippingRates.shippingRates,
						shippingProviderType: currentProviderShippingRates.shippingProviderType
					}
				}),
			"Failed to change shipping rate");
	}

	return <>
		<PageTitle title="Shipping Rates" />
		<>
			{ProviderShippingRates && ProviderShippingRates.length > 0 &&
				ProviderShippingRates?.map(shippingProviderTypeEnum => {
					return createShippingProviderTable(shippingProviderTypeEnum)
				})
			}
		</>
	</>
});