import AddShippingRateDialogComponent from "@components/admin/shipping-rates/AddShippingRateDialogComponent";
import { Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { AdminProviderShippingRatesByEnumsGetResponse } from "@reshopper/admin-client";
import { useState } from "react";

export default function EditShippingRateButtonComponent(props: {
  providerShippingRates: AdminProviderShippingRatesByEnumsGetResponse,
  buttonTitle?: string,
  onClose?: () => void
}): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose && props.onClose();
  }

  return <>
    <Button
        style={{backgroundColor: "#7CC4F7"}}
        startIcon={<CreateIcon />}
        onClick={handleOpen}>
        {props.buttonTitle ?? "Add Shipping Rate"}
    </Button>
    
    <AddShippingRateDialogComponent
      providerShippingRate={props.providerShippingRates}
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
    />
  </>
}