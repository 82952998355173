import EditShippingRateDialogComponent from "@components/admin/shipping-rates/EditShippingRateDialogComponent";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { AdminProviderShippingRatesByEnumsGetResponse, AdminShippingRateResponseEntry } from "@reshopper/admin-client";
import { useState } from "react";

export default function EditShippingRateButtonComponent(props: {
  providerShippingRates: AdminProviderShippingRatesByEnumsGetResponse,
  shippingRate: AdminShippingRateResponseEntry,
  index: number,
  buttonTitle?: string,
  onClose?: () => void
}): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose && props.onClose();
  }

  return <>
    <Button
        startIcon={<EditIcon />}
        onClick={handleOpen}>
        {props.buttonTitle ?? "Edit Shipping Rate"}
    </Button>
    
    <EditShippingRateDialogComponent
      providerShippingRate={props.providerShippingRates}
      shippingRate={props.shippingRate}
      index={props.index}
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
    />
  </>
}