import { adminClient } from "@api/admin/AdminClient";
import { Box, Button, DialogTitle, FormControl, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DateTimePicker } from "@material-ui/pickers";
import { AdminProviderShippingRatesByEnumsGetResponse, AdminShippingRateResponseEntry, ShippingRateDiscount } from "@reshopper/admin-client";
import { performAction } from "@utils/actions";
import { convertMaterialUiPickersDateToDate } from "@utils/miscellaneous";
import { useState } from "react";

export default function EditShippingRateDialogComponent(props: {
	providerShippingRate: AdminProviderShippingRatesByEnumsGetResponse;
	shippingRate: AdminShippingRateResponseEntry;
	index: number;
	isDialogOpen: boolean;
	onClose?: () => void;
}) {
	const [amountInHundredsInternal, setAmountInHundredsInternal] = useState(props.shippingRate?.amountInHundredsInternal ?? 0)
	const [uptilWeight, setUptilWeight] = useState(props.shippingRate?.uptilWeight ?? 0)
	const [amountInHundredsFreeUser, setAmountInHundredsFreeUser] = useState(props.shippingRate?.amountInHundredsFreeUser ?? 0)
	const [amountInHundredsDiscountedFreeUser, setAmountInHundredsDiscountedFreeUser] = useState(props.shippingRate?.discountFree?.discountedPriceInHundreds ?? 0)
	const [amountInHundredsPlus, setAmountInHundredsPlus] = useState(props.shippingRate?.amountInHundredsPlus ?? 0)
	const [amountInHundredsDiscountedPlus, setAmountInHundredsDiscountedPlus] = useState(props.shippingRate?.discountPlus?.discountedPriceInHundreds ?? 0)
	const [fromDateFreeDiscount, setFromDateFreeDiscount] = useState(props.shippingRate.discountFree?.validFromUtc ?? null);
	const [toDateFreeDiscount, setToDateFreeDiscount] = useState(props.shippingRate.discountFree?.validToUtc ?? null);
	const [fromDatePlusDiscount, setFromDatePlusDiscount] = useState(props.shippingRate.discountPlus?.validFromUtc ?? null);
	const [toDatePlusDiscount, setToDatePlusDiscount] =useState(props.shippingRate.discountPlus?.validToUtc ?? null);

	function parseNumber(numberString: string | null | undefined) {
		const value = +(numberString || "");
		if (isNaN(value)) {
			return 0;
		}

		return value;
	}

	const onSubmit = async () => {
		let discountFree: ShippingRateDiscount | undefined;
		if (fromDateFreeDiscount && toDateFreeDiscount) {
			discountFree = { discountedPriceInHundreds: amountInHundredsDiscountedFreeUser, validFromUtc: fromDateFreeDiscount, validToUtc: toDateFreeDiscount }
		}
		else {
			discountFree = undefined;
		}
		let discountPlus: ShippingRateDiscount | undefined;
		if (fromDatePlusDiscount && toDatePlusDiscount) {
			discountPlus = { discountedPriceInHundreds: amountInHundredsDiscountedPlus, validFromUtc: fromDatePlusDiscount, validToUtc: toDatePlusDiscount }
		}
		else {
			discountPlus = undefined;
		}
		let shippingRate: AdminShippingRateResponseEntry = { amountInHundredsInternal: amountInHundredsInternal, uptilWeight: uptilWeight, amountInHundredsFreeUser: amountInHundredsFreeUser, amountInHundredsPlus: amountInHundredsPlus, discountFree: discountFree, discountPlus: discountPlus }

		props.providerShippingRate.shippingRates[props.index] = shippingRate;

		await performAction(
			async () => await adminClient().adminUpdateShippingRatesForProviderShippingRatesPut(
				{
					body: {
						shippingRates: props.providerShippingRate.shippingRates,
						shippingProviderType: props.providerShippingRate?.shippingProviderType
					}
				}),
			"Failed to change credit package");


	}

	return <Dialog
		open={props.isDialogOpen}
		onClose={() => {
			props.onClose && props.onClose()
		}}
	>
		<DialogTitle
			id="edit-dialog-slide-title">{"Shipping Rate"}
		</DialogTitle>
		<DialogContent>
			<TextField
				autoFocus
				margin="dense"
				id="amountInHundredsInternal"
				label="Internal price in hundreds"
				defaultValue={amountInHundredsInternal}
				fullWidth
				onChange={event => setAmountInHundredsInternal(parseNumber(event.target.value))}
			/>
			<TextField
				margin="dense"
				id="uptilWeight"
				label="uptil Weight"
				defaultValue={uptilWeight}
				fullWidth
				onChange={event => setUptilWeight(parseNumber(event.target.value))}
			/>
			<TextField
				margin="dense"
				id="amountInHundredsNormalUser"
				label="Price in hundreds Normal"
				defaultValue={amountInHundredsFreeUser}
				fullWidth
				onChange={event => setAmountInHundredsFreeUser(parseNumber(event.target.value))}
			/>
			<TextField
				margin="dense"
				id="amountInHundredsDiscountedNormalUser"
				label="Discounted price in hundreds Normal"
				defaultValue={amountInHundredsDiscountedFreeUser}
				fullWidth
				onChange={event => setAmountInHundredsDiscountedFreeUser(parseNumber(event.target.value))}
			/>
			<TextField
				margin="dense"
				id="amountInHundredsPlus"
				label="Price in hundreds Plus"
				defaultValue={amountInHundredsPlus}
				fullWidth
				onChange={event => setAmountInHundredsPlus(parseNumber(event.target.value))}
			/>
			<TextField
				margin="dense"
				id="amountInHundredsDiscountedPlusUser"
				label="Discounted price in hundreds Plus"
				defaultValue={amountInHundredsDiscountedPlus}
				fullWidth
				onChange={event => setAmountInHundredsDiscountedPlus(parseNumber(event.target.value))}
			/>
			<Box>
				<FormControl
					style={{
						marginRight: 16
					}}
				>
					<DateTimePicker
						style={{ maxWidth: "132px" }}
						autoOk
						ampm={false}
						inputVariant={"standard"}
						disableFuture={false}
						disablePast={true}
						variant="inline"
						value={fromDateFreeDiscount}
						onChange={(e) => setFromDateFreeDiscount(convertMaterialUiPickersDateToDate(e))}
						label={"Valid from (Normal)"}
						format="Pp"
					/>
				</FormControl>
				<FormControl
					style={{
						marginRight: 16
					}}
				>
					<DateTimePicker
						style={{ maxWidth: "132px" }}
						autoOk
						ampm={false}
						inputVariant={"standard"}
						disableFuture={false}
						disablePast={true}
						variant="inline"
						value={toDateFreeDiscount}
						onChange={(e) => setToDateFreeDiscount(convertMaterialUiPickersDateToDate(e))}
						label={"Valid to (Normal)"}
						format="Pp"
					/>
				</FormControl>
			</Box>
			<Box>
				<FormControl
					style={{
						marginRight: 16
					}}
				>
					<DateTimePicker
						style={{ maxWidth: "132px" }}
						autoOk
						ampm={false}
						inputVariant={"standard"}
						disableFuture={false}
						disablePast={true}
						variant="inline"
						value={fromDatePlusDiscount}
						onChange={(e) => setFromDatePlusDiscount(convertMaterialUiPickersDateToDate(e))}
						label={"Valid from (Plus)"}
						format="Pp"
					/>
				</FormControl>
				<FormControl
					style={{
						marginRight: 16
					}}
				>
					<DateTimePicker
						style={{ maxWidth: "132px" }}
						autoOk
						ampm={false}
						inputVariant={"standard"}
						disableFuture={false}
						disablePast={true}
						variant="inline"
						value={toDatePlusDiscount}
						onChange={(e) => setToDatePlusDiscount(convertMaterialUiPickersDateToDate(e))}
						label={"Valid to (Plus)"}
						format="Pp"
					/>
				</FormControl>
			</Box>
			<Button
				onClick={() => onSubmit()}
				style={{ marginTop: 16 }}
				variant="contained"
				color="primary">Submit
			</Button>
		</DialogContent>
	</Dialog>
}