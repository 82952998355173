import { Box, Typography } from "@material-ui/core";

export default (props: { title: string|JSX.Element, children?: any }) => 
    <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        flexWrap="nowrap"
    >
        <Box>
            <Typography variant="h4">
                {props.title}
            </Typography>
        </Box>
        {props.children && <>
            <Box flexGrow={1} />
            <Box>
                {props.children}
            </Box>
        </>}
    </Box>;